import React, { Component,useState, useEffect, useRef } from "react";

import {
    Steps
} from 'antd';

import {Link} from "react-router-dom";
import '../../forms/enrol';
import '../../css/hurryTimer.css';
import EnrolForm from "../../forms/enrol";
import MyCheckoutForm from '../../forms/MyCheckoutForm';
import moment from "moment/moment";
import API2 from "../../components/api2";
import CountDown from "../../components/Enrol/CountDown";
import useToken from "../../hooks/useToken";
import useWindowDimensions from "../../components/useWindowDimensions";

export default function Enrol(props) {

    const token = props.match.params.token;

    const { geo, setUser } = useToken();

    const AP = API2();
    const { width } = useWindowDimensions();
    const myRef = useRef(null);
    const heightRef = useRef(null);

    const [currentStep, setCurrentStep] = useState('info');
    const [studentData, setStudentData] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const [plans, setPlans] = useState({});
    const [exchangeRates, setExchangeRates] = useState(null);
    const [startDates, setStartDates] = useState([]);
    

    const [isEnrolled, setIsEnrolled] = useState(false);

    const [timeLeftStart, setTimeLeftStart] = useState(false);

    let fdata = {};
    const [formData, setFormData] = useState(false);


  

    function getPlans(planIds){
        let url = '/plans?for=student&pageSize=9999';
        if(planIds) url += '&planIds='+JSON.stringify(planIds);
       
        if(geo){
            url += '&currency='+geo.currency.code;
        }

       

       




        AP.get(url)
            .then(function (res) {
                
                
                let earliestStart = false;
                let defaultDate   = false;
                let now = Date.now()/1000;
                let plans = {};

                let startDates = [];
                for(let plan of res.data){
                    let date = moment.unix(plan.starts_at).format("MMMM [Intake] - DD/MM/YY");

                    if(plan.starts_at > now && (earliestStart==false || earliestStart>plan.starts_at)){
                        earliestStart = plan.starts_at;
                        defaultDate = date;
                    }

                    if(startDates.indexOf(date) == -1){
                        startDates.push(date);
                    }

                    //remove wrong payments
                    const payments = [];
                    for(const payment of plan.payments){
                        if(plan.regional && payment.region) payments.push(payment);
                        else if(!plan.regional && !payment.region) payments.push(payment);
                    }
                    plan.payments = payments;

                    if(typeof plans[date] == 'undefined') plans[date] = [];
                    plans[date].push(plan);

                    
                }

                setTimeLeftStart(earliestStart);
                fdata.start_at = defaultDate;

                if(token && token.length === 2){
                    fdata.country = token;
                }

                setFormData(fdata);
                setStartDates(startDates.sort());
                setPlans(plans);

                if(res.currencies){
                    setExchangeRates(res.currencies);
                }
            });
    }

    useEffect(() => {
        if(currentStep=='payment'){
            window.parent.postMessage(
                {
                    type: 'scrollTop'
                },
                '*'
            );
        }
    }, [currentStep]);

    useEffect(() => {

        if(token && token.length>2){
            AP.post('/decrypt',{value:token})
                .then(function (res) {
                    fdata = res;
                    getPlans(res.plan_ids);
                });
        }else{
            getPlans();
        }

        updateHeight();

    }, []);

    function saveStudentData(data){
		setStudentData(data);
        setCurrentStep('payment');

        window.scrollTo({
            top: myRef.current.offsetTop,
            behavior: 'smooth'
        });
	}
    function paymentSuccess(pi){

        AP.post('/enrol',{
            studentData: studentData,
            intent:pi
        })
            .then(function(res){
                setIsSaving(false);
                setCurrentStep('success');
                
				//setUser(res.user, res.access_token);
				if(props.isIframe){
                    window.parent.location.href = "http://www.sportsnutritionassociation.com/enrolment-thank-you";
                }else{
                    window.location.replace("http://www.sportsnutritionassociation.com/enrolment-thank-you");
                }
            })
            .catch(function(res){
                setIsSaving(false);
            });
	}
    

    const isIframe = window.location.pathname.substring(0, 8) === '/iframe/';

    function updateHeight(){
        if(isIframe){
            setTimeout(()=>{
                window.parent.postMessage(
                    {
                        type: 'adjustIframeHeight',
                        height: heightRef.current.offsetHeight
                    },
                    '*'
                );
                updateHeight();

                /*
                    <script>
                        window.addEventListener('message', function(event) {
                        if (event.data.type === 'adjustIframeHeight') {
                            var iframe = document.querySelector('iframe');
                            if (iframe) {
                            iframe.style.height = (event.data.height+100) + 'px';
                            }
                        }
                        else if (event.data.type === 'scrollTop') {
                            var iframe = document.querySelector('iframe');
                            const rect = iframe.getBoundingClientRect();
                            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                            
                            window.scrollTo({
                                top: rect.top + scrollTop,
                                behavior: 'smooth'
                            });
                        }
                        });
                    </script>
                */
                
            },1000);
        }
    }

    return (
        <div className={isIframe?"content_inner":"content_inner_padded"} style={{height:"100%"}}>
            <div className="container" style={{height:"100%",display:"flex", flexDirection:"column"}}>
                <div className="ibn ibn-sb sec">
                    {!isIframe && !isEnrolled &&
                    <div className="ebn ud-bn ud-pt obg uix-alter ebn-p587x sec" style={{maxHeight: 315}}>
                        <div className="cpnw cov xg">
                            <div className="main">
                                <div className="cpn al-g cl-g bg-g wd-g">
                                    <div className="title">
                                        <h1>Course Enrolment
                                            <div className="breadcrumb">
                                                <a href="https://www.sportsnutrition.nz/">Home</a>
                                                <span className="separator"> &gt; </span>
                                                <span className="current">Course Enrolment</span>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bg hs-bg is-basic" data-height="auto"
                             style={{"backgroundImage": "url('https://www.sportsnutrition.nz/wp-content/uploads/2020/04/blue-page-header.jpg')"}}>

                        </div>
                    </div>
                    }
                    {isEnrolled &&
                        <div className="ebn ud-bn ud-pt obg uix-alter ebn-p639x sec">
                            <div className="cpnw cov xg">
                                <div className="main">
                                    <div className="cpn al-g cl-g bg-g wd-g">
                                        <div className="title">
                                            <h1>Thank You
                                                <div className="breadcrumb">
                                                    <a href="https://sportsnutrition.org.au/">Home</a>
                                                    <span className="separator"> &gt; </span>
                                                    <span className="current">Thank You</span>
                                                </div>
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="bg hs-bg is-basic"
                                data-height="auto"
                                style={{
                                    backgroundImage: "url('https://www.sportsnutrition.org.au/wp-content/uploads/2020/04/ambassadors-header.jpg')",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "50% 50%",
                                    backgroundSize: "cover",
                                    backgroundAttachment: "scroll",
                                    height: "100%",
                                    maxHeight: "inherit",
                                    transition: "height 300ms"
                                }}
                            />
                        </div>
                    }
                </div>

                {!isEnrolled &&
                    <div ref={myRef}  className="site-wrap ipg iopg ipg-course-enrolment sec" style={{flex: 1}}>
                        <div className="wrap wrap-wd wrap-page gb-sd no-aside sec" style={{height: "100%"}}>
                            <div className="icontent sec" style={{height: "100%"}}>
                                <article className="epc epg" style={{height: "100%"}}>
                                    <div className="ientry" style={{height: "100%"}}>
                                        <div
                                            className="section sec-sc sec basic row-xd sec-rg sec-enr ud-pad tpd-s bpd-s ud-bgc sec-a701112z"
                                            style={{backgroundColor: "white", height: "100%"}}>
                                            <div ref={heightRef} className="main">
                                                <div className="content">
                                                    <div style={{marginBottom:120}}>
                                                        <h2 style={{textAlign: "center", fontSize:24, fontWeight:"normal"}}>Enrol ends in</h2>
                                                        <p style={{textAlign: "center"}}></p>
                                                        <CountDown timeLeftStart={timeLeftStart}/>
                                                    </div>
                                                    <div
                                                        className=""
                                                        id="gform_wrapper_3"
                                                    >

                                                        <Steps
                                                            items={[
                                                                {
                                                                    title: 'Student Details',
                                                                    status: (currentStep=='info')?'process':'finish',
                                                                    icon: <i className="fad fa-clipboard-list-check"/>,
                                                                },
                                                                {
                                                                    title: 'Payment Details',
                                                                    status: (currentStep=='info')?'wait':(currentStep=='payment')?'process':'finish',
                                                                    icon: <i className="fad fa-money-check-edit-alt"/>,
                                                                },
                                                                {
                                                                    title: 'Success',
                                                                    status: (['info','payment'].includes(currentStep))?'wait':'process',
                                                                    icon: <i className="fas fa-check"/>,
                                                                }
                                                            ]}
                                                            style={{
                                                                textAlign:(width<700)?"center":"left"
                                                            }}
                                                        />
                                                        {currentStep=='info' && <>
                                                            <EnrolForm
                                                                plans={plans}
                                                                exchangeRates={exchangeRates}
                                                                startDates={startDates}
                                                                formData={formData} {...props}
                                                                isIframe={isIframe}
                                                                setIsEnrolled={setIsEnrolled}

                                                                onSave={saveStudentData}
                                                            />
                                                        </>}
                                                        {currentStep=='payment' && <>
                                                            <div
                                                                style={{
                                                                    maxWidth:500,
                                                                    margin: "50px auto 0 auto"
                                                                }}
                                                            >
                                                                <MyCheckoutForm 
                                                                    memberData={studentData}
                                                                    onSuccess={paymentSuccess}
                                                                    for="student"
                                                                />
                                                            </div>
                                                        </>}
                                                        {currentStep=='success' && <div>success stuff here</div>}



                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                }

                {isEnrolled &&
                    <div className="site-wrap ipg iopg ipg-course-enrolment sec" style={{flex:1}}>
                        <div className="wrap wrap-wd wrap-page gb-sd no-aside sec" style={{height:"100%"}}>
                            <div className="icontent sec" style={{height:"100%"}}>
                                <article className="epc epg" style={{height:"100%"}}>
                                    <div className="ientry" style={{height:"100%"}}>
                                        <div className="section sec-sc sec basic row-xd sec-rg sec-enr ud-pad tpd-s bpd-s ud-bgc sec-a701112z" style={{backgroundColor:"white",height:"100%"}}>
                                            <div className="main">
                                                <div className="content">
                                                    <div className="site-wrap ipg iopg ipg-thank-you sec">
                                                        <div className="main">
                                                            <div className="wrap wrap-rg no-aside wrap-page gb-wd-sd row">
                                                                <div className="icontent g12">
                                                                    <article className="epc epg">
                                                                        <div className="ientry">
                                                                            <h3 style={{textAlign:"center"}}>We have received your information and will be in contact with you soon.</h3>
                                                                        </div>
                                                                    </article>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                }



            </div>
        </div>
    );
}