import React, {useEffect, useState} from 'react';
import { Elements, PaymentElement, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import API2 from "../components/api2";
import {
  Spin,
  Button
} from 'antd';

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isSaving, setIsSaving] = useState(false);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        setIsSaving(true);
    
        if (!stripe || !elements) {
          // Stripe.js hasn't yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        let result;
        if(props.options.object == 'setup_intent'){
            result = await stripe.confirmSetup({
                elements,
                redirect: 'if_required',
                //confirmParams: {
                //    return_url: 'http://your-return-url.com',
                //},
            });
        }else{
            result = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                redirect: 'if_required',
                //confirmParams: {
                //  return_url: "https://example.com/order/123/complete",
                //},
            });
        }

        setIsSaving(false);

console.log(['result',result]);
        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            if(result.setupIntent && result.setupIntent.object == 'setup_intent'){
                if(result.setupIntent.status == 'succeeded'){
                    props.onSuccess(result.setupIntent);
                }
            }else if(result.paymentIntent){ // && result.paymentIntent.object == 'setup_intent'
                if(result.paymentIntent.status == 'succeeded'){
                    props.onSuccess(result.paymentIntent);
                }
            }else{
                console.log(['mycheckoutform handleSubmit', 'fail']);
            }
          }


        
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <div style={{display:"flex", justifyContent:"right", marginTop:20}}>
                <Button disabled={!stripe} type="primary"  htmlType="submit" className="ant-btn-lg">
                    {!!isSaving && <>
                        <Spin className="spin-white" />
                    </>}
                    {!isSaving && <>
                        <div style={{width:170, display: "flex", justifyContent:"space-between"}}>
                            <div>Pay</div>
                            <div>${props.amount/100}</div>
                        </div>
                    </>}
                    
                </Button>
            </div>
        </form>
    );
};

export default function MyCheckoutForm(props) {
    
    const AP = API2();

    const [stripeOptions, setStripeOptions] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);
    const [amount, setAmount] = useState(0);

    useEffect(() => {


        if(props.for == 'member'){
            setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_MEMBER_PUBLIC));
        }else{
            setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_STUDENT_PUBLIC));
        }

        AP.post('/plans/paymentIntent',{
            plan_id: props.memberData.plan_id,
            memberData: props.memberData
        })
            .then(function (res) {
                setStripeOptions({ 
                    object: res.object,
                    clientSecret: res.client_secret 
                });

                if(res.amount){
                    setAmount(res.amount);
                }
            });
    }, []);


    if(!stripeOptions){
        return <div style={{width:200, height:300, display:"flex", justifyContent:"center", alignItems:"center", margin:"auto"}}>
            <Spin/>
        </div>;
    }

    return (
        <Elements stripe={stripePromise} options={stripeOptions}>
            <CheckoutForm options={stripeOptions} amount={amount} {...props}/>
        </Elements>
    );
}
